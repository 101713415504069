'use client';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@/components/shared/typography/typography.component';
import Image from 'next/image';
import { ClockIcon } from '../../../../public/Images';
import { FC } from 'react';

export enum EBadgeWithTooltip {
    RESERVED = 'RESERVED',
    PURCHASE_IN_PROGRESS = 'PURCHASE_IN_PROGRESS',
    null = null,
}

interface IBadgeWithTooltip {
    type: EBadgeWithTooltip;
    translations: Record<string, string>;
}

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        arrow
        sx={{
            display: 'block',
            marginInlineEnd: 'auto',
            backgroundColor: 'white',
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#344054',
        border: '1px solid #EAECF0',
    },
}));

const BadgeWithTooltip: FC<IBadgeWithTooltip> = ({ type, translations }) => {
    return (
        <StyledToolTip
            title={
                <Typography variant="captionMedium">
                    {type === EBadgeWithTooltip.RESERVED
                        ? translations['reservedBadgeTooltipDescription']
                        : translations['purchaseInProgress.descriptionLong']}
                </Typography>
            }
        >
            <div
                className={`flex gap-1 h-7 leading-5 items-center w-fit justify-center align-baseline z-[1] uppercase rounded-lg py-0.5 px-2.5 ${
                    type === EBadgeWithTooltip.PURCHASE_IN_PROGRESS
                        ? ' bg-secondary text-white'
                        : ' font-medium bg-gray-500 text-white whitespace-nowrap '
                }`}
            >
                <Image
                    className={`${
                        type === EBadgeWithTooltip.RESERVED ? 'hidden' : ''
                    }`}
                    loading={'lazy'}
                    src={ClockIcon}
                    alt={'badge-icon'}
                    width={12}
                    height={12}
                />
                <Typography
                    variant="body2Medium"
                    style={{ lineHeight: '20px', height: '20px' }}
                >
                    {type === EBadgeWithTooltip.RESERVED
                        ? translations['reservedFor48h']
                        : translations['purchaseInProgress.soldFlag']}
                </Typography>
            </div>
        </StyledToolTip>
    );
};
export default BadgeWithTooltip;
