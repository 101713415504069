import i18n from '@/translations/client-setup';
import { FieldErrors, FieldValues } from 'react-hook-form';
/* eslint-disable @typescript-eslint/ban-ts-comment */
export const numberWithCommas = (number: number | string | undefined) => {
    if (number !== null && number !== undefined) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
};
export const formatDate = (date: string | Date) => {
    const formatedDate = new Date(date).toLocaleDateString();
    // const time = new Date(date).toLocaleString().substring(10, date.length);
    return formatedDate;
};
export const formatCamelCaseNames = (camelCaseString: string) => {
    const nameArr = camelCaseString.match(/[A-Z][a-z]+/g);
    if (nameArr) {
        const name = nameArr?.join(' ');
        return name;
    }
    return camelCaseString;
};

export const cleanObject = (obj: any) => {
    for (const propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === 'null' ||
            obj[propName] === undefined
        ) {
            delete obj[propName];
        }
    }
    return obj;
};
export const findCommonKeys = <T extends object, U extends object>(
    obj1: T,
    obj2: U
): (keyof T & keyof U)[] => {
    const obj1Keys = Object.keys(obj1) as (keyof U)[];
    const obj2Keys = Object.keys(obj2) as (keyof U)[];
    return obj1Keys.filter(key => obj2Keys.includes(key)) as (keyof T &
        keyof U)[];
};

export const cleanArrayOfObjects = (obj: any) => {
    obj.forEach((properties: any) => {
        for (const propName in properties) {
            if (Array.isArray(propName)) {
                for (const prop in propName) {
                    if (
                        properties[prop] === null ||
                        properties[prop] === 'null' ||
                        properties[prop] === undefined
                    ) {
                        delete propName[prop];
                    }
                }
            } else {
                if (
                    properties[propName] === null ||
                    properties[propName] === 'null' ||
                    properties[propName] === undefined
                ) {
                    delete properties[propName];
                }
            }
        }
    });
    return obj;
};
export const removeNulls = (obj: any) => {
    const isArray = obj instanceof Array;
    for (const k in obj) {
        if (obj[k] === null || obj[k] === 'null' || obj[k] === undefined) {
            //@ts-ignore
            isArray ? obj.splice(k, 1) : delete obj[k];
        } else if (typeof obj[k] == 'object') removeNulls(obj[k]);
        //@ts-ignore
        if (isArray && obj.length === k) removeNulls(obj);
    }
    return obj;
};

export const getQueryVariable = (variable: string) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
};

export const roundToNearest = (num: number, round: number): number => {
    return Math.round(num / round) * round;
};
export const removeCommas = (num: number) => {
    if (!num) return 0;
    return parseInt(num.toString().replace(/[^0-9.١٢٣٤٥٦٧٨٩]/g, '') || '0', 10);
};

export const generateKilometrages = (defaultMaxKM = '120000') => {
    const maxKM = +defaultMaxKM;
    let start = 0;
    let end = 9999;
    const step = 10000;
    const result = [];
    while (end + 1 < maxKM) {
        result.push({
            original: `${start}-${end}`,
            formatted: `${start.toLocaleString()} - ${end.toLocaleString()}`,
            stepperDisplay: `${start.toLocaleString()}-${end.toLocaleString()}`,
        });
        start += step;
        end += step;
    }
    if (start < maxKM) {
        result.push({
            original: `${start}-${maxKM}`,
            formatted: `${start.toLocaleString()} - ${maxKM.toLocaleString()}`,
            stepperDisplay: `${start.toLocaleString()}-${maxKM.toLocaleString()}`,
        });
    }

    return result;
};
export const getMaxKmForYear = (
    data: { year: string; maxKm: string }[],
    year: string
) => {
    let left = 0;
    let right = data.length - 1;
    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        if (data[mid].year === year) {
            return data[mid].maxKm;
        } else if (data[mid].year < year) {
            left = mid + 1;
        } else {
            right = mid - 1;
        }
    }
    return null;
};
export const translateNumbers = (phoneNumber: string | any) => {
    let str = phoneNumber;
    const arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g,
    ];
    if (typeof str === 'string') {
        for (let i = 0; i < 10; i++) {
            str = str.replace(arabicNumbers[i], i);
        }
        return str;
    }
};

export const scrollToChild = (
    parent: HTMLElement,
    child: HTMLElement
): void => {
    const parentRect = parent.getBoundingClientRect();
    const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
    };
    const childRect = child.getBoundingClientRect();
    const isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height;

    if (!isViewable) {
        const scrollTop = childRect.top - parentRect.top;
        parent.scrollTop += scrollTop;
    }
};

export const formatNumber = (number: number) => {
    const abbreviations = {
        K: 1000,
        M: 1000000,
    };

    const keys = Object.keys(abbreviations).reverse();
    const lang = i18n.language;
    const t = i18n.t;
    for (const key of keys) {
        if (number >= abbreviations[key as keyof typeof abbreviations]) {
            const resultNumber = (
                number / abbreviations[key as keyof typeof abbreviations]
            ).toFixed(0);
            const arText = `${t('Off')} ${resultNumber} ${t('K')}`;
            const enText = `${resultNumber}${t('K')} ${t('Off')} `;
            return lang === 'ar' ? arText : enText;
        }
    }

    return number.toString();
};
export const getCarCardLocale = (): Record<string, string> => {
    const t = i18n.t;
    return {
        Off: t('Off'),
        K: t('K'),
        CAR_CARD_LIMITED_DISCOUNT: t('CAR_CARD_LIMITED_DISCOUNT'),
        FEB_DISCOUNT: t('FEB_DISCOUNT'),
        limitedOffer: t('limitedOffer'),
        EGP: t('EGP'),
        from: t('from'),
        '/month': t('/month'),
        km: t('km'),
        automatic: t('automatic'),
        manual: t('manual'),
        reservedBadgeTooltipDescription: t('reservedBadgeTooltipDescription'),
        'purchaseInProgress.descriptionLong': t(
            'purchaseInProgress.descriptionLong'
        ),
        reservedFor48h: t('reservedFor48h'),
        'purchaseInProgress.soldFlag': t('purchaseInProgress.soldFlag'),
    };
};
//To formate the rendered tags (check if use selected whole one make)
export const formatRenderedTags = (
    selectedModelaFlag: {
        makeValue: string;
        icon: any;
        label?: string;
        makeLabel?: string;
    }[],
    selectedModels: {
        makeValue: string;
        modelValue: string;
        icon?: any;
        label: string;
        makeLabel?: string;
    }[],
    anyTranslationText?: string
) => {
    const array1 = selectedModelaFlag.map(obj => obj.makeValue.trim());
    const array2 = selectedModels.map(obj => obj.makeValue.trim());

    const commonModels = array1.filter(model => array2.includes(model));

    const renderedTags = selectedModels?.filter(model => {
        const isCommon = commonModels.filter(
            commonModel => commonModel === model.makeValue
        );

        if (isCommon.length > 0) return false;
        else return true;
    });

    if (selectedModelaFlag && selectedModelaFlag.length > 0) {
        selectedModelaFlag?.map(model => {
            renderedTags.push({
                makeValue: model.makeValue,
                modelValue: 'All',
                icon: model.icon,
                label: anyTranslationText,
                makeLabel: model.makeLabel,
            });
        });
    }
    return renderedTags;
};
export const changeShallowUrl = (url: string) => {
    window.history.replaceState(
        {},
        '',
        new URL(`${i18n.language}/${url}`, window.location.origin)
    );
};
export const formatMoney = (
    number: number,
    thousands: string,
    millions: string
) => {
    if (number >= 1000000) {
        const million = number / 1000000;
        if (million % 1 === 0) {
            return million.toFixed(0) + millions;
        } else {
            return million.toFixed(1) + millions;
        }
    } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + thousands;
    } else {
        return number.toString();
    }
};
export function hasFormError(
    errors: FieldErrors<FieldValues>,
    errorKey: string
) {
    const hasError = Object.keys(errors).some(key => {
        const error = errors[key];
        return error?.type === errorKey;
    });
    return hasError;
}
